import React, { Suspense, useContext, useEffect, useState } from "react";
import { Context } from "../../../utils/Context";
import { getAllBucketFiles } from "../../../utils/s3";
import searchSpinner from "../../../assets/searchSpinner.svg";

const Item = React.lazy(() => import("../shared/Item"));

export default function Recents() {
  const { poolSub, mainSub } = useContext(Context);
  const [isLoading, setIsLoading] = useState(true);
  const [bucketData, setBucketData] = useState([]);

  useEffect(() => {
    if (typeof poolSub !== "undefined") {
      getAllBucketFiles(setBucketData, setIsLoading);
    }
  }, [poolSub]);

  const loadingResultsToggle = () => {
    if (isLoading) {
      return <img src={searchSpinner} className="loading-results" />;
    } else {
      return null;
    }
  };

  const sortedArr = bucketData.sort((a, b) => {
    return new Date(b.LastModified).getTime() >
      new Date(a.LastModified).getTime()
      ? 1
      : -1;
  });

  const AllSubscriptionsArr = sortedArr.filter(
    (item) =>
      String(item.Key).includes("mp3") || String(item.Key).includes("wav")
  );

  const MainOnlyArr = AllSubscriptionsArr.filter(
    (item) => item.Key != "TeaMix Pool"
  );

  const PoolOnlyArr = AllSubscriptionsArr.filter((item) => {
    const fileNameOnServer = item.Key?.toLowerCase();
    if (fileNameOnServer.includes("TeaMix Pool".toLowerCase())) {
      return item;
    } else {
      return null;
    }
  });

  const checkPoolSubscription = () => {
    if (poolSub && mainSub) {
      return AllSubscriptionsArr.slice(0, 20);
    } else if (poolSub && !mainSub) {
      return PoolOnlyArr.slice(0, 20);
    } else if (!poolSub && mainSub) {
      return MainOnlyArr.slice(0, 20);
    }
  };

  return (
    <>
      <div className="headline">
        <h1>העלאות אחרונות</h1>
      </div>
      <div className="files-list">
        <Suspense>
          {loadingResultsToggle()}
          {checkPoolSubscription()
            ? checkPoolSubscription()?.map((item) => {
                if (item.Key) {
                  return <Item itemData={item} key={item.Key} />;
                }
              })
            : null}
        </Suspense>
      </div>
    </>
  );
}
