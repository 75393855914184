import React, { useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Context } from "../../../utils/Context";
import Teamix from "../../../assets/teamix.png";

export default function Navbar({ isActive }) {
  const { colorMode, setCurrentPath } = useContext(Context);
  const navigate = useNavigate();

  const handlePath = (path) => {
    setCurrentPath(path);
  };

  return (
    <div className="navbar">
      <img
        src={Teamix}
        alt="TeaMix"
        onClick={() => navigate("/")}
        style={{ cursor: "pointer" }}
      />
      <nav>
        <li onClick={() => handlePath("העלאות אחרונות")}>
          <NavLink to={"/"} className={colorMode ? "dark" : ""}>
            <svg
              id="clock"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <path
                id="Path_33"
                data-name="Path 33"
                d="M12,24A12,12,0,1,1,24,12,12.013,12.013,0,0,1,12,24ZM12,1.5A10.5,10.5,0,1,0,22.5,12,10.512,10.512,0,0,0,12,1.5Zm0,0"
                fill="#777"
              />
              <path
                id="Path_34"
                data-name="Path 34"
                d="M246,115.082a.744.744,0,0,1-.53-.22l-5.25-5.25a.75.75,0,0,1-.22-.53v-7a.75.75,0,0,1,1.5,0v6.689l5.03,5.03a.751.751,0,0,1-.53,1.281Zm0,0"
                transform="translate(-228.75 -96.582)"
                fill="#777"
              />
            </svg>
            העלאות אחרונות
          </NavLink>
        </li>

        <li onClick={() => handlePath("סייר הקבצים")}>
          <NavLink to={"/explorer"} className={colorMode ? "dark" : ""}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="20.628"
              viewBox="0 0 24 20.628"
            >
              <g id="folder" transform="translate(0 -39.356)">
                <g
                  id="Group_7"
                  data-name="Group 7"
                  transform="translate(0 39.356)"
                >
                  <path
                    id="Path_35"
                    data-name="Path 35"
                    d="M21.043,43.112h-7.6a1.139,1.139,0,0,1-.817-.347l-2.37-2.536a2.98,2.98,0,0,0-2.139-.873H2.957a2.984,2.984,0,0,0-2.957,3V56.98a2.984,2.984,0,0,0,2.957,3h18.1A2.984,2.984,0,0,0,24,56.971V46.115A2.984,2.984,0,0,0,21.043,43.112Zm1.148,13.863a1.159,1.159,0,0,1-1.139,1.172H2.957A1.159,1.159,0,0,1,1.809,56.98V42.359a1.161,1.161,0,0,1,1.148-1.167H8.174a1.132,1.132,0,0,1,.792.321l2.357,2.524a2.949,2.949,0,0,0,2.123.913h7.6a1.159,1.159,0,0,1,1.148,1.166v10.86Z"
                    transform="translate(0 -39.356)"
                    fill="#777"
                  />
                </g>
              </g>
            </svg>
            סייר הקבצים
          </NavLink>
        </li>

        <li onClick={() => handlePath("מה חדש")}>
          <NavLink to={"/whats-new"} className={colorMode ? "dark" : ""}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="26.57"
              viewBox="0 0 24 26.57"
            >
              <g id="notification" transform="translate(-17.958)">
                <g
                  id="Group_81"
                  data-name="Group 81"
                  transform="translate(17.958 0)"
                >
                  <path
                    id="Path_107"
                    data-name="Path 107"
                    d="M38.529,16.8V11.755a8.835,8.835,0,0,0-5.7-8.241,3,3,0,0,0,.06-.587,2.927,2.927,0,1,0-5.854,0,3.134,3.134,0,0,0,.056.57,8.564,8.564,0,0,0-5.7,8.074V16.8a.339.339,0,0,1-.339.339,3.074,3.074,0,0,0-3.077,2.708,3,3,0,0,0,2.987,3.291h5.168a3.855,3.855,0,0,0,7.663,0h5.168a3,3,0,0,0,2.987-3.291,3.078,3.078,0,0,0-3.077-2.708A.337.337,0,0,1,38.529,16.8ZM29.958,1.714a1.214,1.214,0,0,1,1.213,1.213,1.047,1.047,0,0,1-.017.163,7.6,7.6,0,0,0-.883-.081,8.831,8.831,0,0,0-1.509.086,1.048,1.048,0,0,1-.017-.163,1.216,1.216,0,0,1,1.213-1.217Zm0,23.142a2.15,2.15,0,0,1-2.1-1.714h4.2A2.15,2.15,0,0,1,29.958,24.856Zm10.281-4.843a1.281,1.281,0,0,1-1.281,1.414h-18a1.288,1.288,0,0,1-1.281-1.414,1.347,1.347,0,0,1,1.371-1.157A2.056,2.056,0,0,0,23.1,16.8V11.571a6.86,6.86,0,0,1,6.857-6.857c.086,0,.167,0,.253,0a7.011,7.011,0,0,1,6.6,7.037V16.8a2.056,2.056,0,0,0,2.053,2.053A1.35,1.35,0,0,1,40.239,20.014Z"
                    transform="translate(-17.958 0)"
                    fill="#bcbcbc"
                  />
                </g>
              </g>
            </svg>
            מה חדש
          </NavLink>
        </li>
      </nav>
    </div>
  );
}
