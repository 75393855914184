import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../utils/Context";
import { supabase } from "../../../utils/supabase";

export default function AdminAddUpdate() {
  const { colorMode } = useContext(Context);
  const [updateTitle, setUpdateTitle] = useState("");
  const [updateURL, setUpdateURL] = useState("");

  const navigate = useNavigate();

  const handleAddUpdate = async () => {
    try {
      const { data, error } = await supabase
        .from("updates")
        .insert([{ update_title: updateTitle, update_url: updateURL }]);

      if (error) throw error;
      alert("עדכון חדש נוסף בהצלחה");
      navigate("/admin");
    } catch (error) {
      alert(error.error_description || error.message);
    }
  };

  return (
    <div className={`inline-section ${colorMode ? "dark" : ""}`}>
      <div className={`form ${colorMode ? "dark" : ""}`}>
        <input
          type="text"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="שם האוסף"
          onChange={(e) => setUpdateTitle(e.target.value)}
          value={updateTitle}
        />
        <input
          type="text"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="קישור"
          onChange={(e) => setUpdateURL(e.target.value)}
          value={updateURL}
        />
        <button onClick={handleAddUpdate}>הוסף עדכון</button>
      </div>
    </div>
  );
}
