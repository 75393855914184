import React, { useEffect, useState } from "react";
import { supabase } from "../../../utils/supabase";
import Login from "../auth/Login";
import Home from "./Home";
import ReactGA from "react-ga4";

export default function SessionToggle() {
  const [session, setSession] = useState(null);
  useEffect(() => {
    setSession(supabase.auth.session());
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  return (
    <>
      {!session ? <Login /> : <Home key={session.user.id} session={session} />}
    </>
  );
}
