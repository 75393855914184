import React, { Suspense, useEffect, useState } from "react";
import { fetchUpdates } from "../../../utils/supabase";

const Folder = React.lazy(() => import("../shared/Folder"));

export default function WhatsNew() {
  const [recent, setRecent] = useState([]);

  useEffect(() => {
    fetchUpdates(setRecent);
  }, []);

  const sortedArr = recent.sort((a, b) => {
    return new Date(b.created_at).getTime() > new Date(a.created_at).getTime()
      ? 1
      : -1;
  });

  const slicedArr = sortedArr.slice(0, 10);

  return (
    <>
      <div className="headline">
        <h1>מה חדש?</h1>
      </div>
      <div className="files-list">
        <Suspense>
          {recent
            ? slicedArr.map((item, index) => {
                return (
                  <Folder
                    pathName={item.update_title}
                    path_url={item.update_url}
                    key={index}
                  />
                );
              })
            : ""}
        </Suspense>
      </div>
    </>
  );
}
