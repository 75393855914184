import React, { useContext, useEffect } from "react";
import { Context } from "../../../utils/Context";
import { signOut } from "../../../utils/supabase";
import AdminMainContent from "./AdminMainContent";
import AdminTopBar from "./AdminTopBar";

export default function Admin({ session }) {
  const { colorMode } = useContext(Context);

  let sessionUserEmail = session.user.email;

  const adminEmails = [
    "im@menchoo.co.il",
    "teamix555@gmail.com",
    "duduas123@gmail.com",
  ];

  const verifyAuthorized = async () => {
    if (!adminEmails.includes(sessionUserEmail)) {
      alert("אין גישת מנהל");
      signOut();
    }
  };

  useEffect(() => {
    verifyAuthorized();
  }, []);

  return (
    <div className={`container admin ${colorMode ? "dark" : ""}`}>
      <AdminTopBar />
      <div className={`container-ls admin ${colorMode ? "dark" : ""}`}>
        <main className={`main-content ${colorMode ? "dark" : ""}`}>
          <AdminMainContent />
        </main>
      </div>
    </div>
  );
}
