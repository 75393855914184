import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../../utils/Context";
import { signOut, updateUserProfile } from "../../../utils/supabase";

export default function Profile() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subscriptionID, setSubscriptionID] = useState("");
  const { colorMode } = useContext(Context);

  const location = useLocation();
  const userData = location.state.userData.data[0];

  useEffect(() => {
    setEmail(userData.email);
    setFname(userData.first_name);
    setLname(userData.last_name);
    setPhone(userData.phone);
    setSubscriptionID(userData.subscription_id);
  }, []);

  const handleUpdate = () => {
    updateUserProfile(userData.id, fname, lname, email, phone);
  };

  return (
    <div className={`inline-section ${colorMode ? "dark" : ""}`}>
      <div className={`form ${colorMode ? "dark" : ""}`}>
        <input
          type="text"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="שם פרטי"
          onChange={(e) => setFname(e.target.value)}
          value={fname}
        />
        <input
          type="text"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="שם משפחה"
          onChange={(e) => setLname(e.target.value)}
          value={lname}
        />
        <input
          type="text"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="טלפון / נייד"
          onChange={(e) => setPhone(e.target.value)}
          pattern="^\d{10}$"
          value={phone}
        />
        <input
          type="email"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="דואר אלקטרוני"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <button onClick={() => handleUpdate()}>ערוך פרטים</button>
        <button className="signout" onClick={() => signOut()}>
          התנתקות
        </button>
      </div>
    </div>
  );
}
