import React, { useContext, useState } from "react";
import { Context } from "../../../utils/Context";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";

export default function AdminFilters({
  filterByName,
  filterByStatus,
  filterByNextPaymentMonth,
  filterdData,
}) {
  const { colorMode } = useContext(Context);
  const [filters, setFilters] = useState({
    name: "",
    future_payment_date: "",
    status: "",
  });

  const confirmationDialog = () => {
    Swal.fire({
      title: "אישור הפעולה",
      text: `האם ברצונך לשלוח הודעת לחידוש אל ${filterdData.length} נמענים?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "אישור הפעולה",
      cancelButtonText: "ביטול",
      customClass: colorMode ? "dark" : null,
    }).then((result) => {
      if (result.isConfirmed) {
        handleRenewAllInArrayEmail();
      }
    });
  };

  const handleRenewAllInArrayEmail = () => {
    for (let i = 0; i < filterdData.length; i++) {
      emailjs
        .send(
          "service_qiqx75r",
          "template_l413cj8",
          {
            name: filterdData[i].first_name,
            email: filterdData[i].email,
            expDate: filterdData[i].future_payment_date,
          },
          process.env.REACT_APP_EMAILJS_PUBLIC_KEY
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  const handleFilters = (field) => (e) => {
    let { value } = e.target;

    setFilters({
      ...filters,
      [field]: value,
    });

    switch (field) {
      case "name":
        filterByName(value);
        break;

      case "status":
        filterByStatus(value);
        break;

      case "nextPayment":
        filterByNextPaymentMonth(value);
        break;

      default:
        break;
    }
  };

  return (
    <div className="admin-actions">
      <div className={`admin-actions-filters ${colorMode ? "dark" : ""}`}>
        <div className="filter">
          <input
            type="text"
            placeholder="חיפוש לפי שם"
            onChange={handleFilters("name")}
          />
        </div>
        <div className="filter">
          <select name="by-status" onChange={handleFilters("status")}>
            <option value="">כל הסטטוסים</option>
            <option value="active">פעילים</option>
            <option value="canceled">מבוטלים</option>
          </select>
        </div>
        <div className="filter">
          <select name="by-month" onChange={handleFilters("nextPayment")}>
            <option value="">תשלום עתידי לחודש</option>
            <option value="Jan">ינואר</option>
            <option value="Feb">פברואר</option>
            <option value="Mar">מרץ</option>
            <option value="Apr">אפריל</option>
            <option value="<ay">מאי</option>
            <option value="Jun">יוני</option>
            <option value="Jul">יולי</option>
            <option value="Aug">אוגוסט</option>
            <option value="Sep">ספטמבר</option>
            <option value="Oct">אוקטובר</option>
            <option value="Nov">נובמבר</option>
            <option value="Dec">דצמבר</option>
          </select>
        </div>
      </div>
      <div className="admin-actions-btn">
        <button onClick={() => confirmationDialog()}>
          שלח למוצגים לינק לחידוש
        </button>
      </div>
    </div>
  );
}
