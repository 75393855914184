import React, { useContext, useEffect } from "react";
import { Context } from "../../../utils/Context";
import DarkModeIcon from "../../../assets/darkmode.svg";
import LightModeIcon from "../../../assets/lightmode.svg";
import Teamix from "../../../assets/teamix.png";
import { useNavigate } from "react-router-dom";

export default function AdminTopBar() {
  const { colorMode, setColorMode } = useContext(Context);

  const navigate = useNavigate();

  const handleColor = () => {
    if (!colorMode) {
      setColorMode(true);
      document.body.classList.add("dark");
      localStorage.setItem("darkMode", "enabled");
    } else {
      setColorMode(false);
      document.body.classList.remove("dark");
      localStorage.removeItem("darkMode");
    }
  };

  const checkColorMode = () => {
    if (localStorage.getItem("darkMode")) {
      setColorMode(true);
      document.body.classList.add("dark");
    } else {
      setColorMode(false);
      document.body.classList.remove("dark");
    }
  };

  useEffect(() => {
    checkColorMode();
  });

  return (
    <div className="topbar">
      <div className="topbar-rs">
        <img
          src={Teamix}
          alt="TeaMix"
          onClick={() => navigate("/admin")}
          style={{ cursor: "pointer" }}
        />
      </div>

      <div className="topbar-ls">
        <button
          onClick={() => navigate("/admin/add-update")}
          className="notification"
        >
          הוסף עדכון
        </button>
        <button
          onClick={() => navigate("/admin/add-notification")}
          className="notification"
        >
          הוסף הודעת מערכת
        </button>
        <button onClick={() => navigate("/admin/add")}>הוסף משתמש</button>

        <div className="color-box">
          {colorMode ? (
            <img
              onClick={() => handleColor()}
              src={DarkModeIcon}
              alt="מצב כהה"
            />
          ) : (
            <img
              onClick={() => handleColor()}
              src={LightModeIcon}
              alt="מצב כהה"
            />
          )}
        </div>
      </div>
    </div>
  );
}
