import React from "react";

export default function NotificationItem({ notif, isVisible }) {
  const handleNotification = (notifID) => {
    localStorage.setItem("notif_" + notifID, "closed");
    isVisible(false);
  };

  return (
    <div className="item">
      <div className="item-close">
        <span onClick={() => handleNotification(notif.id)}>סגור</span>
      </div>
      <div className="item-image">
        <img src={notif.notif_image} alt={notif.notif_title} />
      </div>
      <div className="item-info">
        <h3>הודעת מערכת</h3>
        <h4>{notif.notif_title}</h4>
      </div>
    </div>
  );
}
