import React, { Suspense, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../utils/Context";
import { getAllBucketFiles, getAllPoolBucketFiles } from "../../../utils/s3";
import searchSpinner from "../../../assets/searchSpinner.svg";

const Item = React.lazy(() => import("../shared/Item"));

export default function Search() {
  const [bucketData, setBucketData] = useState([]);
  const [bucketModifiedData, setBucketModifiedData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { searchTerm, poolSub, mainSub } = useContext(Context);
  const navigate = useNavigate();

  useEffect(() => {
    if (poolSub && mainSub) {
      getAllBucketFiles(setBucketData, setIsLoading);
    } else if (poolSub && !mainSub) {
      getAllPoolBucketFiles(setBucketModifiedData, setIsLoading);
      setBucketData(bucketModifiedData);
    } else if (!poolSub && mainSub) {
      getAllBucketFiles(setBucketModifiedData, setIsLoading);
      setBucketData(
        bucketModifiedData.filter((item) => item.Prefix != "TeaMix Pool/")
      );
    }
  }, [bucketModifiedData]);

  if (searchTerm === "") {
    navigate("/");
  }

  const filterdArr = bucketData.filter((item) => {
    const fileNameOnServer = item.Key?.toLowerCase();
    const searchTermLowerCase = searchTerm.toLowerCase();
    if (fileNameOnServer.includes(searchTermLowerCase)) {
      return item;
    } else {
      return null;
    }
  });

  const loadingResultsToggle = () => {
    if (isLoading) {
      return <img src={searchSpinner} className="loading-results" />;
    } else {
      return null;
    }
  };

  return (
    <>
      <div className="headline">
        <h1>תוצאות חיפוש עבור {searchTerm}</h1>
      </div>
      <div className="files-list">
        <Suspense>
          {loadingResultsToggle()}
          {filterdArr
            ? filterdArr?.map((item) => {
                if (item.Key) {
                  return <Item itemData={item} key={item.Key} />;
                } else {
                  return null;
                }
              })
            : "null"}
        </Suspense>
      </div>
    </>
  );
}
