import React from "react";
import { Outlet } from "react-router-dom";
import Notification from "./Notification";

export default function MainContent() {
  return (
    <>
      <Notification />
      <Outlet />
    </>
  );
}
