import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../utils/Context";
import { supabase } from "../../../utils/supabase";
import Teamix from "../../../assets/teamix.png";

export default function PasswordReset() {
  const { colorMode } = useContext(Context);
  const [email, setEmail] = useState("");
  const [passwordEvent, setPasswordEvent] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  async function handleResetPassword() {
    try {
      const options = {
        redirectTo: "https://app.teamix.co.il/lost-password",
      };
      const { error } = await supabase.auth.api.resetPasswordForEmail(
        email,
        options
      );
      alert("קישור לאיפוס סיסמא נשלח במייל");
      if (error) throw error;
    } catch (error) {
      alert(error.error_description || error.message);
    }
  }

  const handleUpdatePasswordInAPI = async () => {
    const { data, error } = await supabase.auth.update({
      password: newPassword,
    });

    if (data) {
      alert("סיסמא עודכנה בהצלחה");
      window.location.replace("https://app.teamix.co.il");
    }
    if (error) alert("הייתה בעיה במהלך עדכון הסיסמא שלך");
  };

  useEffect(() => {
    supabase.auth.onAuthStateChange(async (event, session) => {
      if (event == "PASSWORD_RECOVERY") {
        setPasswordEvent(true);
      }
    });
  }, []);

  return (
    <div className={`login ${colorMode ? "dark" : ""}`}>
      <div className={`form ${colorMode ? "dark" : ""}`}>
        <img src={Teamix} alt="TeaMix"></img>
        {passwordEvent ? (
          <input
            type="password"
            className={`inputField ${colorMode ? "dark" : ""}`}
            placeholder="סיסמא חדשה"
            onChange={(e) => setNewPassword(e.target.value)}
            value={newPassword}
          />
        ) : (
          <input
            type="email"
            className={`inputField ${colorMode ? "dark" : ""}`}
            placeholder="דואר אלקטרוני"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
        )}
        {passwordEvent ? (
          <button onClick={handleUpdatePasswordInAPI}>אפס סיסמא</button>
        ) : (
          <button onClick={handleResetPassword}>שלח בקשה לאיפוס סיסמא</button>
        )}
      </div>
    </div>
  );
}
