import React, { useContext } from "react";
import { Context } from "../../../utils/Context";
import UserIcon from "../../../assets/admin_user.svg";
import DownloadIcon from "../../../assets/download.svg";
import EditIcon from "../../../assets/edit.svg";
import { useNavigate } from "react-router-dom";
import emailjs from "@emailjs/browser";

export default function AdminUserRow({ userData }) {
  const { colorMode } = useContext(Context);
  const navigate = useNavigate();

  const handleRenewEmail = (name, email, expDate) => {
    const dynamicData = {
      name: name,
      email: email,
      expDate: expDate,
    };

    emailjs
      .send(
        "service_qiqx75r",
        "template_l413cj8",
        dynamicData,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          if (result.text === "OK") {
            alert(`הודעת חידוש נשלחה בהצלחה למייל ${email}`);
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div
      className={`item ${colorMode ? "dark" : ""} ${
        userData.status === "canceled" ? "disabled" : ""
      }`}
    >
      <div className={`thumbnail ${colorMode ? "dark" : ""}`}>
        <img
          src={UserIcon}
          alt={userData.first_name + " " + userData.last_name}
        />
      </div>

      <div
        className="fullname"
        onClick={() => navigate("/admin/edit", { state: { userData } })}
      >
        <h3>
          {userData.first_name} {userData.last_name}
        </h3>
      </div>

      <div className="email">
        <h4>{userData.email}</h4>
      </div>

      <div className="phone">
        <h4>{userData.phone}</h4>
      </div>

      <div className="payment">
        <h4>
          {new Date(userData.last_payment_date).toLocaleDateString("he-IL")}
        </h4>
      </div>

      <div className="payment">
        <h4>
          {new Date(userData.future_payment_date).toLocaleDateString("he-IL")}
        </h4>
      </div>

      <div className="status">
        <h4>{userData.status === "active" ? "פעיל" : "מבוטל"}</h4>
      </div>

      <div className="actions">
        <button
          className={`actions-btn ${colorMode ? "dark" : ""}`}
          onClick={() =>
            handleRenewEmail(
              userData.first_name,
              userData.email,
              userData.future_payment_date
            )
          }
        >
          <img src={DownloadIcon} alt="שליחת לינק לחידוש" />
          שלח לינק לחידוש
        </button>

        <button
          className={`actions-btn ${colorMode ? "dark" : ""}`}
          onClick={() => navigate("/admin/edit", { state: { userData } })}
        >
          <img src={EditIcon} alt="ערוך" />
          ערוך
        </button>
      </div>
    </div>
  );
}
