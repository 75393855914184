import React, { useEffect, useState } from "react";
import { supabase } from "../../../utils/supabase";
import AdminLogin from "../auth/AdminLogin";
import Admin from "./Admin";

export default function AdminSessionToggle() {
  const [session, setSession] = useState(null);
  useEffect(() => {
    setSession(supabase.auth.session());

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  return (
    <>
      {!session ? (
        <AdminLogin />
      ) : (
        <Admin key={session.user.id} session={session} />
      )}
    </>
  );
}
