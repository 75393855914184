import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AdminAddNotification from "./app/screens/admin/AdminAddNotification";
import AdminAddUser from "./app/screens/admin/AdminAddUser";
import AdminEditUser from "./app/screens/admin/AdminEditUser";
import AdminSessionToggle from "./app/screens/admin/AdminSessionToggle";
import AdminUserManagment from "./app/screens/admin/AdminUserManagment";
import Explorer from "./app/screens/explorer/Explorer";
import Profile from "./app/screens/home/Profile";
import SessionToggle from "./app/screens/home/SessionToggle";
import Recents from "./app/screens/recents/Recents";
import Search from "./app/screens/search/Search";
import "./styles/app.scss";
import { ContextProvider } from "./utils/Context";
import PaymentPage from "./app/screens/payments/PaymentPage";
import PasswordReset from "./app/screens/auth/PasswordReset";
import LogRocket from "logrocket";
import WhatsNew from "./app/screens/whats-new/WhatsNew";
import AdminAddUpdate from "./app/screens/admin/AdminAddUpdate";
import ReactGA from "react-ga4";

ReactGA.initialize("G-MCPNWDNRZY");
LogRocket.init("m4vfps/teamix");

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <ContextProvider>
      <Routes>
        <Route path="/" element={<SessionToggle />}>
          <Route index element={<Recents />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/q" element={<Search />} />
          <Route path="/whats-new" element={<WhatsNew />} />
          <Route path="/explorer" element={<Explorer />} />
          <Route path="/explorer?path=:folderId" element={<Explorer />} />
        </Route>
        <Route path="/admin" element={<AdminSessionToggle />}>
          <Route index element={<AdminUserManagment />} />
          <Route path="/admin/add" element={<AdminAddUser />} />
          <Route path="/admin/edit" element={<AdminEditUser />} />
          <Route
            path="/admin/add-notification"
            element={<AdminAddNotification />}
          />
          <Route path="/admin/add-update" element={<AdminAddUpdate />} />
        </Route>
        <Route path="/subscription" element={<PaymentPage />} />
        <Route path="/lost-password" element={<PasswordReset />} />
      </Routes>
    </ContextProvider>
  </BrowserRouter>
);
