import React, { useEffect, useState } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { supabase } from "../../../utils/supabase";

export default function PaymentButton({
  type,
  email,
  password,
  fName,
  lName,
  phone,
  subscriptionType,
}) {
  const [{ options }, dispatch] = usePayPalScriptReducer();
  const [subscriptionID, setSubscriptionID] = useState("");
  const [isLoading, setLoading] = useState(false);

  async function handleSignup(
    subscriptionID,
    email,
    password,
    fName,
    lName,
    phone
  ) {
    try {
      setLoading(true);
      const { user, error } = await supabase.auth.signUp({
        email: email ? email : "",
        password: password ? password : "",
      });

      if (error) {
        console.log(error);
        setLoading(false);
        alert("קרתה תקלה בהרשמה, נא פנה למנהל מערכת");
      }

      setTimeout(async () => {
        if (user && !error) {
          console.log("user created, trying to update");
          const { data, error } = await supabase
            .from("users")
            .update({
              first_name: fName,
              last_name: lName,
              email: email,
              phone: phone,
              pool_status: "active",
              status: "active",
              subscription_id: subscriptionID,
            })
            .select("*")
            .eq("id", user.id);

          if (error) {
            console.log(error);
            setLoading(false);
            alert("קרתה תקלה בהרשמה, נא פנה למנהל מערכת");
          }

          if (data && !error) {
            setLoading(false);
            alert("הרשמתך בוצעה בהצלחה.");
            console.log("user updated");
            console.log(data);
          }
        }
      }, 2000);
    } catch (error) {
      alert(error.error_description || error.message);
    }
  }

  const validateForm = () => {
    if (
      email === "" ||
      fName === "" ||
      lName === "" ||
      phone === "" ||
      password === "" ||
      password.length <= 7 ||
      !email.includes("@") ||
      !email.includes(".")
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        intent: "subscription",
      },
    });
  }, [type]);

  const handleQueryPlans = () => {
    if (subscriptionType === "existing") {
      return process.env.REACT_APP_PAYPAL_NO_FEE_PLAN;
    }
    // if (subscriptionType === "testonly") {
    //   return process.env.REACT_APP_PAYPAL_TEST_PLAN;
    // }
    if (subscriptionType === "vipsale") {
      return process.env.REACT_APP_PAYPAL_VIPSALE_PLAN;
    } else {
      return process.env.REACT_APP_PAYPAL_PLAN;
    }
  };

  return (
    <PayPalButtons
      disabled={validateForm() || isLoading}
      createSubscription={(data, actions) => {
        return actions.subscription
          .create({
            plan_id: handleQueryPlans(),
          })
          .then((orderId) => {
            setSubscriptionID(orderId);
            return orderId;
          });
      }}
      style={{
        label: "subscribe",
        shape: "pill",
      }}
      onApprove={() =>
        handleSignup(subscriptionID, email, password, fName, lName, phone)
      }
    />
  );
}
