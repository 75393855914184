import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Context } from "../../../utils/Context";
import { deleteUser, updateUserAdmin } from "../../../utils/supabase";

export default function AdminEditUser() {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [lastPaymentDate, setLastPaymentDate] = useState("");
  const [nextPaymentDate, setNextPaymentDate] = useState("");
  const [status, setStatus] = useState("");
  const [poolStatus, setPoolStatus] = useState("");
  const [paypalId, setPayPalId] = useState("");
  const { colorMode } = useContext(Context);

  const location = useLocation();
  const userData = location.state.userData;

  useEffect(() => {
    setEmail(userData.email);
    setFname(userData.first_name);
    setLname(userData.last_name);
    setPhone(userData.phone);
    setStatus(userData.status);
    setLastPaymentDate(userData.last_payment_date);
    setNextPaymentDate(userData.future_payment_date);
    setPoolStatus(userData.pool_status);
    setPayPalId(userData.subscription_id)
  }, []);

  const handleUpdate = () => {
    updateUserAdmin(
      userData.id,
      fname,
      lname,
      email,
      phone,
      lastPaymentDate,
      nextPaymentDate,
      status,
      poolStatus,
      paypalId
    );
  };

  const handleDelete = () => {
    deleteUser(userData.id);
  }

  return (
    <div className={`inline-section ${colorMode ? "dark" : ""}`}>
      <div className={`form ${colorMode ? "dark" : ""}`}>
        <input
          type="text"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="שם פרטי"
          onChange={(e) => setFname(e.target.value)}
          value={fname}
        />
        <input
          type="text"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="שם משפחה"
          onChange={(e) => setLname(e.target.value)}
          value={lname}
        />
        <input
          type="text"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="טלפון / נייד"
          onChange={(e) => setPhone(e.target.value)}
          pattern="^\d{10}$"
          value={phone}
        />
        <input
          type="email"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="דואר אלקטרוני"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <input
          type="text"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="מזהה מנוי בפייפאל"
          onChange={(e) => setPayPalId(e.target.value)}
          value={paypalId}
        />
        <p>תאריך התשלום האחרון</p>
        <input
          type="date"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="תאריך תשלום אחרון"
          onChange={(e) => setLastPaymentDate(e.target.value)}
          value={lastPaymentDate}
        />
        <p>תאריך התשלום הבא</p>
        <input
          type="date"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="תאריך תשלום הבא"
          onChange={(e) => setNextPaymentDate(e.target.value)}
          value={nextPaymentDate}
        />

        <select
          name="status"
          onChange={(e) => setStatus(e.target.value)}
          value={status}
          className={`inputField ${colorMode ? "dark" : ""}`}
        >
          <option value="">בחר סטטוס TeaMix</option>
          <option value="active">פעיל</option>
          <option value="canceled">ללא מנוי TeaMix</option>
        </select>

        <select
          name="status"
          onChange={(e) => setPoolStatus(e.target.value)}
          value={poolStatus}
          className={`inputField ${colorMode ? "dark" : ""}`}
        >
          <option value="">בחר סטטוס Pool</option>
          <option value="active">פעיל</option>
          <option value="canceled">ללא מנוי Pool</option>
        </select>

        <button onClick={() => handleUpdate()}>ערוך משתמש</button>
        <button onClick={() => handleDelete()}>מחק משתמש</button>

      </div>
    </div>
  );
}
