import {
  S3Client,
  ListObjectsCommand,
  ListObjectsV2Command,
} from "@aws-sdk/client-s3";

export const s3Client = new S3Client({
  endpoint: "https://fra1.digitaloceanspaces.com",
  region: "fra1",
  credentials: {
    accessKeyId: process.env.REACT_APP_S3_KEY,
    secretAccessKey: process.env.REACT_APP_S3_SECRET,
  },
});

export const fetchBucketContents = async (statePusher, path) => {
  try {
    const data = await s3Client.send(
      new ListObjectsCommand({
        Bucket: "teamixcollection",
        Prefix: path,
      })
    );

    statePusher(data.Contents);
    return data.Contents;
  } catch (err) {
    console.log(err);
  }
};

export const getAllBucketFiles = async (statePusher, loadStatus) => {
  let list = [];
  let shouldContinue = true;
  let nextContinuationToken = null;
  while (shouldContinue) {
    let res = await s3Client.send(
      new ListObjectsV2Command({
        Bucket: "teamixcollection",
        ContinuationToken: nextContinuationToken || undefined,
      })
    );

    list.push(...res.Contents);

    if (!res.IsTruncated) {
      shouldContinue = false;
      nextContinuationToken = null;
    } else {
      nextContinuationToken = res.NextContinuationToken;
    }
  }
  statePusher(list);
  loadStatus(false);
};

export const getAllPoolBucketFiles = async (statePusher, loadStatus) => {
  let list = [];
  let shouldContinue = true;
  let nextContinuationToken = null;
  while (shouldContinue) {
    let res = await s3Client.send(
      new ListObjectsV2Command({
        Bucket: "teamixcollection",
        Prefix: "TeaMix Pool/",
        ContinuationToken: nextContinuationToken || undefined,
      })
    );

    list.push(...res.Contents);

    if (!res.IsTruncated) {
      shouldContinue = false;
      nextContinuationToken = null;
    } else {
      nextContinuationToken = res.NextContinuationToken;
    }
  }
  statePusher(list);
  loadStatus(false);
};

export const fetchBucketDirectories = async (statePusher, path) => {
  try {
    const data = await s3Client.send(
      new ListObjectsCommand({
        Bucket: "teamixcollection",
        Delimiter: "/",
        Prefix: path || "",
      })
    );
    statePusher(data.CommonPrefixes);
    return data.CommonPrefixes;
  } catch (err) {
    console.log(err);
  }
};
