import React, { useEffect, useState } from "react";
import { supabase } from "../../../utils/supabase";
import AdminFilters from "./AdminFilters";
import AdminUserRow from "./AdminUserRow";

export default function AdminUserManagment() {
  const [data, setData] = useState([]);
  const [filterdData, setFilterdData] = useState([]);

  const fetchUsers = async () => {
    const { data } = await supabase
      .from("users")
      .select("*")
      .order("first_name", { ascending: true });

    setData(data);
    setFilterdData(
      data.sort((a, b) => {
        return a.first_name + a.last_name > b.first_name + b.last_name ? 1 : -1;
      })
    );
  };

  const onlyActiveUsers = data.filter((item) => item.status === "active");

  useEffect(() => {
    fetchUsers();
  }, []);

  const filterByName = (name) => {
    const filterd = data.filter((user) => {
      if (name === "") {
        return user;
      } else if (
        user.first_name?.includes(name) ||
        user.last_name?.includes(name)
      ) {
        return user;
      }
    });

    setFilterdData(filterd);
  };

  const filterByStatus = (status) => {
    const filterd = data.filter((user) => {
      if (status === "") {
        return user;
      } else if (user.status == status) {
        return user;
      }
    });

    setFilterdData(filterd);
  };

  const filterByNextPaymentMonth = (month) => {
    const filterd = data.filter((user) => {
      if (month === "") {
        return user;
      } else if (
        new Date(user.future_payment_date).toLocaleString("default", {
          month: "short",
        }) === month
      ) {
        return user;
      }
    });

    setFilterdData(filterd);
  };

  return (
    <>
      <div className="headline admin">
        <h1>ניהול משתמשים</h1>
        <h3>
          {data.length} משתמשים רשומים, {onlyActiveUsers.length} משתמשים פעילים
        </h3>
      </div>
      <AdminFilters
        filterByName={filterByName}
        filterByStatus={filterByStatus}
        filterByNextPaymentMonth={filterByNextPaymentMonth}
        filterdData={filterdData}
      />
      <div className="files-list">
        {filterdData
          ? filterdData.map((user) => {
              return <AdminUserRow userData={user} key={user.id} />;
            })
          : null}
      </div>
    </>
  );
}
