import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DarkModeIcon from "../../../assets/darkmode.svg";
import LightModeIcon from "../../../assets/lightmode.svg";
import { Context } from "../../../utils/Context";
import PaymentButton from "./PaymentButton";

export default function PaymentPage() {
  const { colorMode, setColorMode } = useContext(Context);
  const [email, setEmail] = useState("");
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  let subscriptionType = searchParams.get("type");

  const payPalOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_CLIENT,
    components: "buttons",
    intent: "subscription",
    vault: true,
  };

  const handleColor = () => {
    if (!colorMode) {
      setColorMode(true);
      document.body.classList.add("dark");
      localStorage.setItem("darkMode", "enabled");
    } else {
      setColorMode(false);
      document.body.classList.remove("dark");
      localStorage.removeItem("darkMode");
    }
  };

  const checkColorMode = () => {
    if (localStorage.getItem("darkMode")) {
      setColorMode(true);
      document.body.classList.add("dark");
    } else {
      setColorMode(false);
      document.body.classList.remove("dark");
    }
  };

  useEffect(() => {
    checkColorMode();
    const body = document.querySelector("body");
    const html = document.querySelector("html");
    body.style.overflow = "visible";
    html.style.overflow = "visible";
  });

  const checkPathForPrice = () => {
    if (subscriptionType === "vipsale") {
      return 416;
    }
    if (subscriptionType === "testonly") {
      return 5;
    }
    if (subscriptionType === "existing") {
      return 360;
    } else {
      return 360;
    }
  };

  return (
    <div className="subscription">
      <div className="color-box">
        {colorMode ? (
          <img onClick={() => handleColor()} src={DarkModeIcon} alt="מצב כהה" />
        ) : (
          <img
            onClick={() => handleColor()}
            src={LightModeIcon}
            alt="מצב כהה"
          />
        )}
      </div>
      <div className={`inline-section ${colorMode ? "dark" : ""}`}>
        <h2>מועמד יקר ברוכים הבאים לשרותי TeaMix</h2>
        <p>
          מחיר השירות הוא: ₪{checkPathForPrice()} לכל חודש.
          <br />
          המחיר כולל עמלת PayPal.
          <br />
          <br />
          שימו לב: המנוי מתחדש אוטומטית.
          <br />
          <br />
          *כל השימוש בשרת הינו לשימוש עצמי פרטי בלבד אין להעביר, לשלוח או למכור
          את הקבצים הקיימים לאף גורם חיצוני
          <br />
          במידה ונמצא הדלפה מהמשתמש שלך הנך יוצא מהשרת ללא החזר כספי וללא אופציה
          לחזור לשרת*
          <br />
          <br />
          שימוש נעים,
          <br />
          צוות TeaMix
        </p>
        <div className={`form ${colorMode ? "dark" : ""}`}>
          <input
            type="text"
            className={`inputField ${colorMode ? "dark" : ""}`}
            placeholder="שם פרטי"
            onChange={(e) => setFName(e.target.value)}
            value={fName}
          />
          <input
            type="text"
            className={`inputField ${colorMode ? "dark" : ""}`}
            placeholder="שם משפחה"
            onChange={(e) => setLName(e.target.value)}
            value={lName}
          />
          <input
            type="text"
            pattern="^[0-9]{10}$"
            className={`inputField ${colorMode ? "dark" : ""}`}
            placeholder="טלפון נייד"
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
          />
          <input
            type="email"
            className={`inputField ${colorMode ? "dark" : ""}`}
            placeholder="דואר אלקטרוני"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          <input
            type="password"
            className={`inputField ${colorMode ? "dark" : ""}`}
            placeholder="סיסמא"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
          />
          <PayPalScriptProvider options={payPalOptions}>
            <PaymentButton
              type="subscription"
              password={password}
              email={email}
              fName={fName}
              lName={lName}
              phone={phone}
              subscriptionType={subscriptionType}
            />
          </PayPalScriptProvider>
        </div>
      </div>
    </div>
  );
}
