import LogRocket from "logrocket";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../utils/Context";
import { fetchUpdates, signOut, supabase } from "../../../utils/supabase";
import MainContent from "./MainContent";
import Navbar from "./Navbar";
import Player from "./Player";
import TopBar from "./TopBar";

export default function Home({ session }) {
  const {
    colorMode,
    setSessionEmail,
    setPoolSub,
    setSessionFullName,
    setMainSub,
  } = useContext(Context);

  let sessionId = session.user.id;
  let sessionUserEmail = session.user.email;

  const verifyValidSubscription = async () => {
    const data = await supabase.from("users").select("*").eq("id", sessionId);
    setSessionFullName(data.data[0].first_name + " " + data.data[0].last_name);

    LogRocket.identify(session.user.id, {
      name: data.data[0].first_name + " " + data.data[0].last_name,
      email: session.user.email,
    });

    if (
      data.data[0].status === "canceled" &&
      data.data[0].pool_status === "canceled"
    ) {
      alert("המנוי שלך אינו בתוקף");
      signOut();
    }

    // Check Main Subscription
    if (data.data[0].status === "active") {
      setMainSub(true);
    } else {
      setMainSub(false);
    }

    // Check Pool Subscription
    if (data.data[0].pool_status === "active") {
      setPoolSub(true);
    } else {
      setPoolSub(false);
    }
  };

  useEffect(() => {
    verifyValidSubscription();
    setSessionEmail(sessionUserEmail);
  }, []);

  return (
    <div className={`container ${colorMode ? "dark" : ""}`}>
      <div className={`container-rs ${colorMode ? "dark" : ""}`}>
        <Navbar />
      </div>
      <div className={`container-ls ${colorMode ? "dark" : ""}`}>
        <TopBar sessionId={sessionId} />
        <main className={`main-content ${colorMode ? "dark" : ""}`}>
          <MainContent />
        </main>
        <Player />
      </div>
    </div>
  );
}
