import React, { Suspense, useEffect, useState } from "react";
import { fetchBucketContents, fetchBucketDirectories } from "../../../utils/s3";
import { useSearchParams } from "react-router-dom";
import { useContext } from "react";
import { Context } from "../../../utils/Context";

const Item = React.lazy(() => import("../shared/Item"));
const Folder = React.lazy(() => import("../shared/Folder"));

export default function Explorer() {
  const { colorMode, setFolderName, poolSub, mainSub } = useContext(Context);
  const [bucketData, setBucketData] = useState([]);
  const [itemData, setItemData] = useState([]);
  const [folderData, setFolderData] = useState();

  const [searchParams, setSearchParams] = useSearchParams();

  var folderId = searchParams.get("path");

  const figureOutWhatToFetch = () => {
    Promise.all([
      fetchBucketDirectories(setFolderData, folderId),
      fetchBucketContents(setItemData, folderId),
    ])
      .then((responses) => {
        const directories = responses[0] || [];
        const directoryContent = responses[1] || [];
        const folderPrefix = directories?.map((i) => {
          return i.Prefix;
        });

        const filteredDirectories = directoryContent?.filter((item) => {
          const result = folderPrefix?.findIndex((element) => {
            return item.Key == folderId ? true : item.Key.includes(element);
          });
          return result == -1 ? item : "";
        });

        const finalBucketData = directories.concat(filteredDirectories);
        const sortedFinalData = finalBucketData.sort(
          (a, b) => a.Prefix?.replace(/\D/g, "") - b.Prefix?.replace(/\D/g, "")
        );

        setBucketData(sortedFinalData);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (typeof poolSub !== "undefined") {
      figureOutWhatToFetch();
    }
  }, [folderId, poolSub]);

  return (
    <>
      <div className="headline">
        <h1>סייר הקבצים</h1>
      </div>
      <div className="files-list">
        <Suspense>
          {bucketData
            ? bucketData?.map((item) => {
                if (item.Prefix) {
                  return (
                    <Folder
                      folderName={item.Prefix}
                      key={item.Prefix}
                      pathName={setFolderName(item.Prefix)}
                    />
                  );
                }
                if (item.Key) {
                  return (
                    <Item itemData={item} folderId={folderId} key={item.Key} />
                  );
                }
              })
            : null}
        </Suspense>
      </div>
    </>
  );
}
