import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../utils/Context";
import { supabase } from "../../../utils/supabase";

export default function AdminAddNotification() {
  const { colorMode } = useContext(Context);
  const [notifContent, setNotifContent] = useState("");
  const [notifImage, setNotifImage] = useState("");

  const navigate = useNavigate();

  const handleAddNotification = async () => {
    try {
      const { data, error } = await supabase
        .from("notifications")
        .insert([{ notif_title: notifContent, notif_image: notifImage }]);

      await supabase
        .from("notifications")
        .delete()
        .match({ id: data[0]?.id - 1 });

      if (error) throw error;
      alert("הודעת מערכת נוספה בהצלחה");
      navigate("/admin");
    } catch (error) {
      alert(error.error_description || error.message);
    }
  };

  return (
    <div className={`inline-section ${colorMode ? "dark" : ""}`}>
      <div className={`form ${colorMode ? "dark" : ""}`}>
        <input
          type="text"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="תוכן ההודעה"
          onChange={(e) => setNotifContent(e.target.value)}
          value={notifContent}
        />
        <input
          type="text"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="קישור לתמונה"
          onChange={(e) => setNotifImage(e.target.value)}
          value={notifImage}
        />
        <button onClick={handleAddNotification}>הוסף הודעת מערכת</button>
      </div>
    </div>
  );
}
