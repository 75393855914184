import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Context } from "../../../utils/Context";
import { supabase } from "../../../utils/supabase";

export default function AdminAddUser() {
  const { colorMode } = useContext(Context);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  async function handleSignup() {
    try {
      const { error } = await supabase.auth.signUp({
        email: email,
        password: password,
      });
      if (error) throw error;
      alert("המשתמש נוצר בהצלחה");
      navigate("/admin");
    } catch (error) {
      alert(error.error_description || error.message);
    }
  }

  return (
    <div className={`inline-section ${colorMode ? "dark" : ""}`}>
      <div className={`form ${colorMode ? "dark" : ""}`}>
        <input
          type="email"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="דואר אלקטרוני"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <input
          type="password"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="סיסמא"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
        />
        <button onClick={handleSignup}>רשום משתמש</button>
      </div>
    </div>
  );
}
