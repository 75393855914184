import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../utils/Context";
import DarkModeIcon from "../../../assets/darkmode.svg";
import LightModeIcon from "../../../assets/lightmode.svg";
import UserIcon from "../../../assets/user.svg";
import SearchIcon from "../../../assets/search.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import { supabase } from "../../../utils/supabase";

export default function TopBar({ sessionId }) {
  const { colorMode, setColorMode, currentPath, setSearchTerm } =
    useContext(Context);
  const [userData, setUserData] = useState();
  const [searchInput, setSearchInput] = useState("");
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const directoryPath = searchParams.get("path");

  const fetchUserData = async () => {
    const data = await supabase.from("users").select("*").eq("id", sessionId);

    setUserData(data);
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleColor = () => {
    if (!colorMode) {
      setColorMode(true);
      document.body.classList.add("dark");
      localStorage.setItem("darkMode", "enabled");
    } else {
      setColorMode(false);
      document.body.classList.remove("dark");
      localStorage.removeItem("darkMode");
    }
  };

  const checkColorMode = () => {
    if (localStorage.getItem("darkMode")) {
      setColorMode(true);
      document.body.classList.add("dark");
    } else {
      setColorMode(false);
      document.body.classList.remove("dark");
    }
  };

  const handleSearch = (e) => {
    if (e.key === "Enter") {
      setSearchTerm(searchInput);
      navigate("/q");
    }
  };

  useEffect(() => {
    checkColorMode();
  });

  return (
    <div className="topbar">
      <div className="topbar-rs">
        <h2 className={`active ${colorMode ? "dark" : ""}`}>
          <span onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
            עמוד הבית
          </span>
          <svg
            id="right-arrow"
            xmlns="http://www.w3.org/2000/svg"
            width="5.066"
            height="8.624"
            viewBox="0 0 5.066 8.624"
          >
            <g id="Group_2" data-name="Group 2" transform="translate(0)">
              <path
                id="Path_29"
                data-name="Path 29"
                d="M101.616,3.975,105.453.138a.473.473,0,0,1,.667,0L106.4.42a.472.472,0,0,1,0,.667L103.18,4.31l3.226,3.226a.473.473,0,0,1,0,.667l-.283.282a.473.473,0,0,1-.667,0l-3.841-3.841a.476.476,0,0,1,0-.669Z"
                transform="translate(-101.478 0)"
              />
            </g>
          </svg>
          {directoryPath
            ? directoryPath.replace(/\//g, " - ").slice(0, -3)
            : currentPath}
        </h2>
      </div>

      <div className="topbar-ls">
        <div className="color-box">
          {colorMode ? (
            <img
              onClick={() => handleColor()}
              src={DarkModeIcon}
              alt="מצב כהה"
            />
          ) : (
            <img
              onClick={() => handleColor()}
              src={LightModeIcon}
              alt="מצב כהה"
            />
          )}
        </div>

        <div className={`search-box ${colorMode ? "dark" : ""}`}>
          <img src={SearchIcon} alt="חיפוש" />
          <input
            type="text"
            placeholder="חיפוש"
            onChange={(e) => setSearchInput(e.target.value)}
            onKeyDown={handleSearch}
          />
        </div>

        <div className="profile-box">
          <img
            src={UserIcon}
            alt="פרופיל אישי"
            onClick={() => navigate("/profile", { state: { userData } })}
          />
        </div>
      </div>
    </div>
  );
}
