import React, { useContext, useState } from "react";
import { Context } from "../../../utils/Context";
import { supabase } from "../../../utils/supabase";
import Teamix from "../../../assets/teamix.png";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const { colorMode } = useContext(Context);
  const navigate = useNavigate();

  const [email, setEmail] = useState();
  const [password, setPasword] = useState();

  async function handleLogin() {
    try {
      const { error } = await supabase.auth.signIn({
        email: email,
        password: password,
      });
      if (error) throw error;
    } catch (error) {
      alert(error.error_description || error.message);
    }
  }

  return (
    <div className={`login ${colorMode ? "dark" : ""}`}>
      <div className={`form ${colorMode ? "dark" : ""}`}>
        <img src={Teamix} alt="TeaMix"></img>
        <input
          type="email"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="דואר אלקטרוני"
          onChange={(e) => setEmail(e.target.value)}
          value={email}
        />
        <input
          type="password"
          className={`inputField ${colorMode ? "dark" : ""}`}
          placeholder="סיסמא"
          onChange={(e) => setPasword(e.target.value)}
          value={password}
        />
        <button onClick={handleLogin}>התחברות</button>
        <button
          onClick={() => navigate("/lost-password")}
          className="password-reset-button"
        >
          שכחתי סיסמא
        </button>
      </div>
    </div>
  );
}
