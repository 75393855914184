import React, { useState } from "react";

export const Context = React.createContext();
export const ContextProvider = ({ children }) => {
  const [colorMode, setColorMode] = useState(false);
  const [currentPath, setCurrentPath] = useState("העלאות אחרונות");
  const setFolderName = (name) => {
    const splitName = name.split("/").filter(Boolean);
    if (splitName.length > 1) return splitName[splitName.length - 1];
    return splitName[0];
  };
  const [currentAudioFile, setCurrentAudioFile] = useState("");
  const [currentAudioName, setCurrentAudioName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [sessionEmail, setSessionEmail] = useState("");
  const [sessionFullName, setSessionFullName] = useState("");
  const [poolSub, setPoolSub] = useState();
  const [mainSub, setMainSub] = useState();

  return (
    <Context.Provider
      value={{
        colorMode,
        setColorMode,
        currentPath,
        setCurrentPath,
        setFolderName,
        currentAudioFile,
        setCurrentAudioFile,
        currentAudioName,
        setCurrentAudioName,
        searchTerm,
        setSearchTerm,
        sessionEmail,
        setSessionEmail,
        poolSub,
        setPoolSub,
        mainSub,
        setMainSub,
        setSessionFullName,
        sessionFullName,
      }}
    >
      {children}
    </Context.Provider>
  );
};
