import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../../utils/Context";
import { fetchNotifications } from "../../../utils/supabase";
import NotificationItem from "../shared/NotificationItem";

export default function Notification() {
  const { colorMode } = useContext(Context);
  const [visible, isVisible] = useState(true);
  const [notifications, setNotifications] = useState();

  const checkNotificationStateOnLoad = () => {
    const localStorageArr = Object.keys(localStorage);

    for (let i = 0; i < localStorageArr.length; i++) {
      if (localStorageArr.includes("notif_" + i)) {
        isVisible(false);
      }
    }
  };

  useEffect(() => {
    fetchNotifications(setNotifications);
    checkNotificationStateOnLoad();
  }, []);

  return (
    <div
      className={`notification-bar ${colorMode ? "dark" : ""} ${
        visible ? "" : "hidden"
      }`}
    >
      {notifications
        ? notifications.map((notif) => {
            return (
              <NotificationItem
                key={notif.id}
                notif={notif}
                visible={visible}
                isVisible={isVisible}
              />
            );
          })
        : null}
    </div>
  );
}
