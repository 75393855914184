import { createClient } from "@supabase/supabase-js";

const supabaseUrl = "https://beeducmidmestzmvjsre.supabase.co";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJlZWR1Y21pZG1lc3R6bXZqc3JlIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NjI4ODU1OTcsImV4cCI6MTk3ODQ2MTU5N30.Zv6ctvwCz296oCDVzk6BUJbl1-taGFHM083vt5baS4g";

export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export const fetchNotifications = async (statePusher) => {
  const { data } = await supabase
    .from("notifications")
    .select()
    .order("id", { ascending: true });

  statePusher(data);
};

export const fetchUpdates = async (statePusher) => {
  const { data } = await supabase
    .from("updates")
    .select()
    .order("created_at", { ascending: true });

  statePusher(data);
};

export const updateUserAdmin = async (
  id,
  first_name,
  last_name,
  email,
  phone,
  last_payment_date,
  next_payment_date,
  status,
  pool_status,
  subscription_id
) => {
  const { data } = await supabase
    .from("users")
    .update({
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: phone,
      last_payment_date: last_payment_date,
      future_payment_date: next_payment_date,
      status: status,
      pool_status: pool_status,
      subscription_id: subscription_id,
    })
    .eq("id", id);

  if (data) {
    window.location.replace("https://app.teamix.co.il/admin");
  }
};

export const deleteUser = async (id, email) => {
  const { data } = await supabase.from("users").delete().eq("id", id);

  if (data) {
    window.location.replace("https://app.teamix.co.il/admin");
  }
};

export const signOut = async () => {
  const { error } = await supabase.auth.signOut();
};

export const updateUserProfile = async (
  id,
  first_name,
  last_name,
  email,
  phone,
  last_payment_date,
  next_payment_date,
  status,
  pool_status
) => {
  const { data } = await supabase
    .from("users")
    .update({
      first_name: first_name,
      last_name: last_name,
      email: email,
      phone: phone,
      last_payment_date: last_payment_date,
      future_payment_date: next_payment_date,
      status: status,
      pool_status: pool_status,
    })
    .eq("id", id);

  if (data) {
    window.location.replace("https://app.teamix.co.il/");
  }
};
