import React, { useContext } from "react";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/src/styles.scss";
import { Context } from "../../../utils/Context";

export default function Player() {
  const { currentAudioFile, colorMode, currentAudioName } = useContext(Context);

  return (
    <div
      className={`audio-player ${currentAudioFile ? "" : "hidden"} ${
        colorMode ? "dark" : ""
      }`}
    >
      <AudioPlayer
        header={currentAudioName}
        autoPlay={false}
        showJumpControls={false}
        customAdditionalControls={[]}
        showDownloadProgress={false}
        src={currentAudioFile}
        layout="horizontal-reverse"
      />
    </div>
  );
}
